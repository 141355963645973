<template>
    <div>
        <el-dialog title=""  custom-class="vipToastDialog"  :visible.sync="dialogVisible" width="35%" :before-close="handleClose">
            <div class="text">
                <div class="textno">！</div>
            </div>
            <div class="textVip">
                <!-- 免费 使用 <span>{{ Text }} </span>次 , <span>开通VIP无限使用</span> -->
                <span v-show="type == 'ZZ'">开通VIP即可使用</span>
                <span  v-show="type == 'ZN'">开通智能VIP即可使用</span>
            </div>
            <div class="btn" @click="toVip">
                开通VIP
            </div>
        </el-dialog>

    </div>
</template>

<script>
export default {
    props: ['type'],
    data() {
        return {
            dialogVisible: false,
            Text: 0
        }
    },
    methods: {
        toVip() {
           this.dialogVisible = false
            this.$router.push({ path: '/vip' })
        },
        hand() {
            this.dialogVisible = true
        },
        handleClose(){
          this.dialogVisible = false
        }

    }
}
</script>

<style lang="scss" scoped>
span {
    color: #69CD96;
}

.text {
    width: 100px;
    height: 100px;
    border: 2px solid orange;
    border-radius: 50%;
    margin: auto;
    position: relative;

    .textno {
        width: 60px;
        height: 60px;
        font-size: 60px;
        color: orange;
        position: absolute;
        top: 12px;
        left: 37px;
        font-weight: 600;
    }




}




</style>
