<template>
  <div class="fillingZZ">
    <cardVip ref="cardVip" type="ZZ"></cardVip>
    <div class="cj">
      <setScore @userInfoScuuess="userInfoScuuess" ref="setScoreRef"></setScore>
    </div>
    <div class="minw">
      <!-- 搜索条件头部 -->
      <fillingInfo ref="fillingInfoREF" ZZORZN="ZZ" :batchFile="batchFile" :key="fillingInfoTime"></fillingInfo>
      <!-- 条件模糊搜索 -->
      <div style="display: flex; align-items: center;">
        <div class="searchInput searchInputNorest">
          <el-input v-model="majorName" @keyup.enter.native.stop="sousuo('search')" placeholder="请输入专业">
            <el-input slot="prepend" v-model="collegeName" @keyup.enter.native.stop="sousuo('search')"
              placeholder="请输入院校"></el-input>
          </el-input>
          <!-- <div class="divicon" style="background: rgb(250, 250, 250);
    border: 1px solid #12B098;
    box-sizing: border-box;" @click="sousuo('search')">
            <i class="el-icon-search" style="color: #12B098;font-weight: 900;"></i>
          </div> -->
        </div>
        <div>
          <el-button type="success" size="small" plain round
            style="width: 100px;background: #12b098 !important;color: white;margin-left:5px;font-size: 14px;"
            @click="sousuo('search')">搜索</el-button>
          <el-button type="success" size="small" plain round
            style="width: 100px;background: #12b098 !important;color: white;margin:0 5px;font-size: 14px;"
            @click="lookDetail">预览志愿表</el-button>
          <div class="resetBtnText" @click="reset">重置</div>
        </div>
        <div class="newMajor" @click="newMajorSearch">
          <span class="kuang">
            <span v-show="isNewMajor" class="rightIcon">√</span>
          </span>
          <span class="kuangText">只看新增</span>
        </div>
      </div>
      <!-- 冲稳保+提示 -->
      <div style="display: flex;align-items: center;margin-top: 13px;">


        <div class="cwbCss">
          <!-- <p @click="cwbChange(1)" :class="{activeCWB:status == 1}">
            不限

          </p> -->
          <p style="width: 28%;">
            共 <span style="color: rgb(18, 176, 152)">{{ allTotalObject.ztotal || '--' }}</span> 条数据
          </p>
          <p :class="{ c: status == 2 }" style="border-radius: 20px;">
            <span class="c pItem" v-show="status != 2"></span>
            <!-- <span @click="cwbChange(2)"  class="cwbtext">
              冲({{ score >= 740 ? `750-${score}` : `${+score + 10}-${score}` }})
            </span> -->
            <span @click="cwbChange(2)" class="cwbtext">
              冲({{ allTotalObject.cnumber }})
            </span>
          </p>
          <p :class="{ w: status == 3 }" style="border-radius: 20px;">
            <span class="w pItem" v-show="status != 3"></span>
            <!-- <span @click="cwbChange(3)" class="cwbtext">
              稳({{ score - 1 }}-{{ score - 10 }})
            </span> -->
            <span @click="cwbChange(3)" class="cwbtext">
              稳({{ allTotalObject.wnumber }})
            </span>
          </p>

          <p :class="{ b: status == 4 }" style="border-radius: 20px;">
            <span class="b pItem" v-show="status != 4"></span>
            <!-- <span @click="cwbChange(4)"  class="cwbtext">
              保({{ score - 11 }}-{{ score - 25 }})
            </span> -->
            <span @click="cwbChange(4)" class="cwbtext">
              保({{ allTotalObject.bnumber }})
            </span>
          </p>

        </div>
        <!-- <div class="volunteerTip">

          【友情提醒】2024年分数和位次为预估，请谨慎参考
        </div> -->
      </div>

      <!-- 数据展示区域 -->
      <intellDetails :isB="true" ref="intellDetailsZZ" :currentBatch="true" type="ZZ" :tableData="tableData"
        :loading="loading" :yearsList="yearsList" :pageNum="queryParams.pageNum" :batchFile="batchFile"></intellDetails>
      <!-- 分页 -->
      <pagination style="position:relative;z-index:2" layout="prev, pager, next,jumper" v-if="queryParams.total > 0"
        :total="queryParams.total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize"
        @pagination="pageChange()" />
      <img v-if="!loading && tableData.length != 0 && $store.state.userInfo.userType == '01'" style="width: 1200px;display:block;margin: 0 auto;position:relative;z-index:3;margin-top:-35px"
        src="../../assets/imgs/zzToast.png" alt="" @click="$router.push('/vip')">
    </div>
  </div>
</template>

<script>


import {
  REQvolunteer,
  REQcollegeGenre,
  REQvolunteerAdd,
  REQscore,
  REQRank,
} from "@/api/aspiration.js";
import { numberDigit } from "@/utils/request.js";
import setScore from "@/components/setscore.vue";
import { mapState } from 'vuex'
import cardVip from '@/components/cardVip.vue'
import { setVolunteerResultPamas } from '@/api/professionalinvestigation.js'
import { REQZFBPAY, REQZFBPAYFree } from "@/api/pay.js";
import {
  getOutgoing_time,
  getSecondEndTime,
  getTime0623,
  getTime0802,
} from "@/utils/time.js";
export default {
  name: "fillingZZ",
  components: {
    setScore,
    cardVip
  },
  props: {},
  computed: { ...mapState(['userType']) },
  data() {
    return {
      queryParams: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
      },
      originOfStudent: '河北省',
      ranking: '',
      loading: false,
      yearsList: [],
      collegeName: "",
      majorName: "",
      searchType: '',
      tableData: [],
      subjects: '',
      score: '',
      status: 1,
      batchFile: '本科批',
      optionalSubject: [],
      fillingInfoTime: new Date().getTime(),
      allTotalObject: {
        ztotal: '',
        bnumber: '',
        cnumber: '',
        wnumber: '',
      },
      isNewMajor:false
    };
  },
  methods: {
    // 每次筛选条件点击的时候
    changeActive(index, type) {
      if (type == "yxxz") {
        //院校性质
        this.yxxzList[index].show = !this.yxxzList[index].show;
      } else if (type == "yxcc") {
        //院校层次，单选
        this.education.forEach((item) => {
          item.show = false;
        });
        this.education[index].show = true;
      } else if (type == "yxts") {
        //院校特色
        this.educational[index].show = !this.educational[index].show;
      } else if (type == "yxlx") {
        //院校类型
        this.collegeGenreList[index].show = !this.collegeGenreList[index].show;
      } else if (type == "xkpg") {
        //学科评估
        this.xkpgList[index].show = !this.xkpgList[index].show;
      } else if (type == "csdj") {
        //城市等级
        this.csdjList[index].show = !this.csdjList[index].show;
      } else if (type == "dlqy") {
        // 地理区域
        this.dlqyList[index].show = !this.dlqyList[index].show;
      } else if (type == "zslx") {
        //招生类型
        this.zslxList[index].show = !this.zslxList[index].show;
      } else if (type == "jyfx") {
        //就业方向
        this.jyfxList[index].show = !this.jyfxList[index].show;
      }
    },
    // 冲稳保点击的时候
    cwbChange(status) {
      if (this.status == status) {
        this.status = 1
      } else {
        this.status = status;
      }
      this.queryParams.pageNum = 1
      this.sousuo()
    },

    shujuset(type) {
      if (this.$route.query.hasOwnProperty('fensInput')) {  //如果是从首页携带分数和科目过来的
        this.shuj = this.$route.query;
        this.score = this.shuj.fensInput;
        this.subject = this.shuj.selectedFruits;
        this.batchFile = this.shuj.selectedFruits3;
        localStorage.setItem('batchFile', this.batchFile)
        this.optionalSubject = this.shuj.selectedFruits2;
        this.$nextTick(() => {
          this.$refs.setScoreRef.score = this.score;
          this.$refs.setScoreRef.subject = this.subject;
          this.$refs.setScoreRef.checkList = this.shuj.selectedFruits2;
          //  如果首页携带过来的分数或 || 科目 || 次选科目，不一致，则进行修改。否则不修改。
          if (this.score != this.$store.state.userInfo.score || this.subject != this.$store.state.userInfo.subject || JSON.stringify(this.optionalSubject) != this.$store.state.userInfo.optionalSubject) {
            this.$refs.setScoreRef.set1()
          } else {
            this.sousuo('search')
          }
        })
      } else {   //如果不是首页携带参数过来，则读取个人中心的分数
        this.score = this.$store.state.userInfo.score;
        this.subject = this.$store.state.userInfo.subject;
        if (!this.subject || !this.score) {
          this.$message.warning('请先完善个人信息')
          return
        }
        this.optionalSubject = this.$store.state.userInfo.optionalSubject;
        // 更新子组件的分数和选科
        this.$nextTick(() => {
          this.$refs.setScoreRef.score = this.score;
          this.$refs.setScoreRef.info.score = this.score;
          this.$refs.setScoreRef.subject = this.subject;
          this.$refs.setScoreRef.info.subject = this.subject;
          this.$refs.setScoreRef.optionalSubject = this.optionalSubject;
          this.$refs.setScoreRef.info.optionalSubject = this.optionalSubject;
        })
        this.batchFile =  localStorage.getItem('batchFile') || '本科批';
        if (type == 'mounted') {  //只有第一次才调取接口
          this.sousuo('search')
        } else if (type == 'activated' && this.tableData.length == 0) {
          this.sousuo('search')
        }
      }
    },
    // 子组件传过来的方法
    userInfoScuuess(type) {
      if (type == 'success') {
        this.score = this.$refs.setScoreRef.score || this.$store.state.userInfo.score;
        this.subject = this.$refs.setScoreRef.subject || this.$store.state.userInfo.subject;
        this.sousuo('search')
      } else if (type == 'fail') {
        this.score = this.$store.state.userInfo.score;
        this.subject = this.$store.state.userInfo.subject;
        return
        this.sousuo('search')
      }

    },
    // 新增专业搜索的时候
    newMajorSearch(){
        this.isNewMajor = !this.isNewMajor
        this.sousuo('search')
    },
    // 分页器change的时候
    async pageChange() {
      const { userType, phonenumber } = this.$store.state.userInfo;

      if (userType == "01") {
        this.queryParams.pageNum = 1;
        this.$refs.cardVip.hand()
        return
      }
      this.sousuo()
    },
    async sousuo(type) {
      const { isVolunteer, phonenumber } = this.$store.state.userInfo
      if (type == 'search') {
        this.queryParams.pageNum = 1
      }
      let obj = {
        searchType: this.searchType,  // 1：院校  2：专业【弃用】
        batchFile: this.batchFile || '本科批',
        // batchFileType: batchFileType, batchFileType取值：batchFile == 本科批 ？ 1 ： ''
        originOfStudent: this.originOfStudent,
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        status: this.status, //不限、冲、稳、保
        score: this.score, //分数
        subjects: this.subject,   //选科
        particularYear: '', //当前年
        ranking: '',  //位次 this.ranking
        userId: phonenumber,
        paramsType:1,
        searchParams: '',
        collegeName: this.collegeName,   //学校名称
        majorName: this.majorName,   //专业名称
        collegeLabel: this.$refs.fillingInfoREF.educational.filter(item => item.show).map(item => item.name).join('|'),       //院校特色---[985/211]
        collegeNature: this.$refs.fillingInfoREF.yxxzList.filter(item => item.show).map(item => item.name).join('|'),       //院校性质---[公办/民办/中外合作办学]
        collegeType: this.$refs.fillingInfoREF.education.filter(item => item.show).map(item => item.name).join('|'),    //院校层次 【本科。专科】
        collegeGenre: this.$refs.fillingInfoREF.collegeGenreList.filter(item => item.show).map(item => item.name).join('|'),   //院校类型【综合、理工、财经】
        gbnumbers: '',     //专业代码---[第三级专业的专业代码(010101|010102|010103K|010104T)],后边会处理
        grade: this.$refs.fillingInfoREF.xkpgList.filter(item => item.show).map(item => item.name).join('|'),  //学科评估结果
        collegeLocality: '',  //地区---[石家庄、衡水]，后边会处理。
        collegeArea: this.$refs.fillingInfoREF.dlqyList.filter(item => item.show).map(item => item.name).join('|'), //地理区域【华北、华东,
        cityLevel: this.$refs.fillingInfoREF.csdjList.filter(item => item.show).map(item => item.name).join('|'),  //城市等级  【一线、二线】
        recruitStudentsCategory: this.$refs.fillingInfoREF.zslxList.filter(item => item.show).map(item => item.name).join('|'), //招生类型
        jyfx: this.$refs.fillingInfoREF.jyfxList.filter(item => item.show).map(item => item.jyfxId).join('|'),   //就业方向
          typen:this.isNewMajor ? 1 : ''  //1：只查新增，否则查全部
      };
      if (!obj.subjects || !obj.score) {
        this.$message.warning('请先完善个人信息')
        return
      }
      // 专业类别处理
      if (this.$refs.fillingInfoREF.gbnumbers.length == 0) {
        obj.gbnumbers = "";
      } else {
        this.$refs.fillingInfoREF.gbnumbers.forEach((item, index) => {
          obj.gbnumbers += item[2] + "|";
        });
      }
      // 如果专业类别最后一个字符串是'|',那么将最后一个字符串删除掉再传给后端
      if (obj.gbnumbers.toString().charAt(obj.gbnumbers.toString().length - 1) == "|") {
        obj.gbnumbers = obj.gbnumbers.toString().substr(0, obj.gbnumbers.toString().length - 1); // 截取该字符串的length-1
      }
      // 地区名称处理
      if (this.$refs.fillingInfoREF.areaNameType.length == 0) {
        obj.collegeLocality = "";
      } else {
        this.$refs.fillingInfoREF.areaNameType.forEach((item, index) => {
          obj.collegeLocality += item[1] + "|";
        });
      }
      // 如果地区名称最后一个字符串是'|',那么将最后一个字符串删除掉再传给后端
      if (obj.collegeLocality.toString().charAt(obj.collegeLocality.toString().length - 1) == "|") {
        obj.collegeLocality = obj.collegeLocality.toString().substr(0, obj.collegeLocality.toString().length - 1); // 截取该字符串的length-1
      }
      // 对collegeLabel处理，如果包含【双一流】，就加上 '|一流建设学科'
      if (obj.collegeLabel.includes('双一流')) {
        obj.collegeLabel = obj.collegeLabel + '|一流建设学科'
      }
      if (obj.collegeLabel.includes('原部委直属')) {
        obj.collegeLabel = obj.collegeLabel + '|原属'
      }
      for (const key in obj) {
        if (typeof (obj[key]) == 'string' && obj[key].includes('不限')) {
          obj[key] = ''
        }
      }
      this.getRank(obj.score, obj.subjects).then(() => {
        this.onload(obj);
      })
      setVolunteerResultPamas(obj).then(res => {

      })
      // console.log('院校性质', this.$refs.fillingInfoREF.yxxzList.filter(item => item.show).map(item => item.name).join('|'))
      // console.log('院校层次', this.$refs.fillingInfoREF.education.filter(item => item.show).map(item => item.name).join('|'))
      // console.log('院校特色', this.$refs.fillingInfoREF.educational.filter(item => item.show).map(item => item.name).join('|'))
      // console.log('院校类型', this.$refs.fillingInfoREF.collegeGenreList.filter(item => item.show).map(item => item.name).join('|'))
      // console.log('专业名称', obj.gbnumbers)
      // console.log('学科评估', this.$refs.fillingInfoREF.xkpgList.filter(item => item.show).map(item => item.name).join('|'))
      // console.log('地区名称', this.$refs.fillingInfoREF.areaNameType)
      // console.log('城市等级', this.$refs.fillingInfoREF.csdjList.filter(item => item.show).map(item => item.name).join('|'))
      // console.log('地理区域', this.$refs.fillingInfoREF.dlqyList.filter(item => item.show).map(item => item.name).join('|'))
      // console.log('招生类型', this.$refs.fillingInfoREF.zslxList.filter(item => item.show).map(item => item.name).join('|'))
      // console.log('就业方向', this.$refs.fillingInfoREF.jyfxList.filter(item => item.show).map(item => item.name).join('|'))


    },
    // 获取表格数据
    async onload(obj) {
      const { userType, phonenumber } = this.$store.state.userInfo;
      const self = this;
      this.loading = true;
      // 位次在这里处理获取
      obj.ranking = this.ranking
      // 年份逻辑处理
      const serverTime = new Date(this.$store.state.serverTime);
      const nowDate = serverTime.getTime();
      let particularYear = "";
      let ZLtime = await this.getAlltime("6"); // 6是查询招录时间

      if (nowDate < Date.parse(ZLtime)) {
        particularYear = serverTime.getFullYear() - 1;
      } else {
        particularYear = serverTime.getFullYear();
      }
      // 年份在这里处理
      obj.particularYear = particularYear;
      if (userType == "01") { //普通用户，只展示第一页的数据
            this.queryParams.pageNum = 1;
            obj.pageNum = 1;
          }
       const bkTime = await this.getAlltime("9") // 9是查询报考期间，返回数组。第0项是开始时间，第1项是结束时间
      const startTime = new Date(bkTime[0]).getTime()      //将开始时间转换为时间戳
      const endTime = new Date( bkTime[1]).getTime()         //将结束时间转换为时间戳
       // 如果该用户是 测试账号，直接执行
      if (this.$store.state.accountNumber.includes(phonenumber)) {

      }else{
                if (nowDate < startTime) {   //出分之前

        } else if (nowDate >= startTime && nowDate <= endTime) {  //填报期间

          if (userType == "05" || userType == "02" || userType == "03" || userType == "04") {
            // 能且只能高三学生使用
            if (!this.$store.state.userInfo.expirationTime) {
              return this.$message.error('未检测到该会员的到期时间，请联系管理员')
            }
            const expirationTime = this.$store.state.userInfo.expirationTime.split('-')[0]
            const nowYear = new Date(nowDate).getFullYear()
            if (nowYear != expirationTime) {   //如果当前年和该学生的毕业时间不相等，则说明不是高三
              this.$message.warning('填报期间非高三考生禁止使用')
              this.loading = false;
              return
            }
          }
        } else if (nowDate > endTime) {   //出分之后

        }
      }


      // console.log(obj)

      REQvolunteer(obj).then((res) => {
        if (res.code == 200) {

          self.yearsList = res.data.yearsList;
          self.allTotalObject = {
            ztotal: res.data.dataTable.total,
            cnumber: res.data.cnumber,
            wnumber: res.data.wnumber,
            bnumber: res.data.bnumber,
          }
          res.data.dataTable.rows.forEach((item) => {
            item.itemDivList = [
              { score: "1~10", volunnter: "志愿一", iconFlag: false },
              { score: "11~20", volunnter: "志愿二", iconFlag: false },
              { score: "21~30", volunnter: "志愿三", iconFlag: false },
              { score: "31~40", volunnter: "志愿四", iconFlag: false },
              { score: "41~50", volunnter: "志愿五", iconFlag: false },
              { score: "51~60", volunnter: "志愿六", iconFlag: false },
              { score: "61~70", volunnter: "志愿七", iconFlag: false },
              { score: "71~80", volunnter: "志愿八", iconFlag: false },
              { score: "81~90", volunnter: "志愿九", iconFlag: false },
              { score: "91~96", volunnter: "志愿十", iconFlag: false },
            ];
            if (item.scorePlansEntity.collegeLabel) {
              if (item.scorePlansEntity.collegeLabel == 0) {
                item.scorePlansEntity.collegeLabel = '---';
              } else {
                // collegeLabel是个字符串，有可能包含逗号（，）有可能包含顿号（、）
                // 所以写了这个判断
                if (item.scorePlansEntity.collegeLabel.indexOf(",") != -1) {
                  item.scorePlansEntity.collegeLabel =
                    item.scorePlansEntity.collegeLabel.replaceAll(",", '/');
                } else if (
                  item.scorePlansEntity.collegeLabel.indexOf("、") != -1
                ) {
                  item.scorePlansEntity.collegeLabel =
                    item.scorePlansEntity.collegeLabel.replaceAll("、", '/');
                }
              }
            }
            item.isShow = false;
            item.flag = false;
          });

          self.tableData = res.data.dataTable.rows;
          self.queryParams.total = res.data.dataTable.total;
          // self.$refs.intellDetailsZZ.resetShow()
          self.loading = false;

        }
      }).catch(err => {

        if (err.msg == '请开通相应的权限') {
          this.$refs.cardVip.hand()
        }
        this.loading = false;
        this.tableData = []
      });
    },
    // 重置
    reset() {
      this.$refs.fillingInfoREF.resetForm()
      this.collegeName = ''
      this.majorName = ''
      this.isNewMajor = false
      this.sousuo('search')
    },
    // 根据分数获取位次
    async getRank(score, subjects) {
      const self = this
      let particularYear = ''
      const serverTime = new Date(this.$store.state.serverTime)
      const nowDate = serverTime.getTime()        //获取当前时间
      const startTime = await this.getAlltime('1')    //1是查询位次时间
      if (nowDate < Date.parse(startTime)) {
        particularYear = serverTime.getFullYear() - 1
      } else {
        particularYear = serverTime.getFullYear()
      }
      const obj = {
        levelProvince: this.originOfStudent,
        particularYear: particularYear,
        subjects,
        score
      }
      await REQRank(obj).then((res) => {
        if (res.code == 200) {
          self.ranking = res.data.cumulativePopulation || ''
        }
      })
    },
    // 跳转到自主填报表
    lookDetail() {
      this.$router.push({
        path: '/individual2',
        // query: { zzorzn: 0 }
      })
    }
  },

  mounted() {

  },
  activated() {
    this.shujuset('activated')
    // this.shujuset()

  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 如果是从志愿表过来的，不进行清空搜索条件
        if(from.path == '/individual2' || from.path == '/voluntaryform'){

        }else{  //否则就清空
            vm.$refs.fillingInfoREF.resetForm()
            vm.$refs.fillingInfoREF.infoOkChange('ottr').then(()=>{
              vm.$refs.fillingInfoREF.getzY()

            })
        }
    })
  }
};
</script>

<style scoped lang="scss">
.fillingZZ {
  margin-top: 30px;
  position: relative;

  .searchTitle {
    flex: 6;
    padding-left: 15px;
  }

  .searchInput {
    margin-left: 7px;
    // flex: 6;
  }



  .active {
    background: #12b098;
    color: white;
    border-radius: 8px;
  }
}

.cj {
  position: absolute;
  top: 0;
  left: 71%;
  top: -8px;
}
.newMajor{
  display: flex;
  align-items: center;
  margin:0 30px 0 auto;
  cursor: pointer;
  .kuang{
    width: 20px;
    height: 20px;
    border: 3px solid #12b098;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #12b098;
    font-size: 20px;
    margin-right: 5px
}

.rightIcon{

}
.kuangText{
  color:#12b098
}
}

</style>
