<template>
  <div>
    <div style="display: flex;">
      <div class="fldv">成绩:{{ info.score }} {{ info.subject }}/{{ JSON.parse(info.optionalSubject).toString() }}<i style="margin-left: 5px;cursor: pointer;color: #12b098 ;font-size: 18px;" @click="handClick"
          class="el-icon-edit"></i>
      </div>
    </div>
    <el-dialog title="成绩修改" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-form ref="formRef" label-width="auto">
        <el-form-item label="分数" prop="name"><el-input v-model="score" :disabled="inputDis" placeholder="请输入" /></el-form-item>
        <el-form-item label="科目" prop="zone">
          <el-radio v-model="subject" :disabled="subjectDis" label="物理">物理</el-radio>
          <el-radio v-model="subject" :disabled="subjectDis" label="历史">历史</el-radio>
        </el-form-item>
        <el-form-item label="再选科目" prop="time">
          <el-checkbox-group v-model="checkList" @change="hand()"  :disabled="optionSubjectDis" >
            <el-checkbox label="生物"></el-checkbox>
            <el-checkbox label="地理"></el-checkbox>
            <el-checkbox label="化学"></el-checkbox>
            <el-checkbox label="政治"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="set1">修改</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
import { getUserInfo, REGUserEditNoPass } from '@/api/user'
import { mapState } from 'vuex'

export default {
  name: 'setScore',
  computed: {
    ...mapState({
      userType: state => state.userInfo.userType
    })
  },
  data() {
    return {
      dialogVisible: false,
      from: [],
      subject: '',
      score: '',
      checkList: [],
      info: {

      },
      inputDis: false,
      subjectDis: false,
      optionSubjectDis:false
    }
  },
  created() {
    this.info = this.$store.state.userInfo
    this.checkList = this.info.optionalSubject ? JSON.parse(this.info.optionalSubject) : []
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
    },
    async handClick() {
      // 判断用户是否可以修改 分数+选科
      this.dialogVisible = true;
      const userInfo = this.$store.state.userInfo;
      let promiChange = await this.isChangeScoreOrSubject(userInfo.score, userInfo.isUpdateScore, userInfo.subject,userInfo.trans)
      this.inputDis = promiChange.inputDis
      this.subjectDis = promiChange.subjectDis
      this.optionSubjectDis = promiChange.optionSubjectDis
    },
    hand() {
      if (this.checkList.length > 2) {
        this.checkList.shift()
      }
    },
    set1() {
       if (!this.score) {
          this.$message.warning('请完善分数')
          return
        }
        if (this.score > 750 || this.score < 0 || isNaN(this.score)) {
          return this.$message.warning('请输入正确的分数')
        }
      this.info.score =  this.score;
      this.info.subject = this.subject;
      this.info.optionalSubject = JSON.stringify(this.checkList)
      const objCopy = JSON.parse(JSON.stringify(this.info))
      const phonenumber = localStorage.getItem('phonenumber')
      delete objCopy.password // 删除密码字段，后端不需要

      REGUserEditNoPass(objCopy).then(async res => {
        if (res.code == 200) {
          this.$message.success('修改分数科目成功')
          await this.$store.dispatch('setUserinfo') // 重新获取个人信息
           this.info = this.$store.state.userInfo;
            this.dialogVisible = false
            this.$emit('userInfoScuuess','success')   //告诉父组件更新成功，是否需要重新获取数据
        }
      }).catch(err=>{
         this.info  = this.$store.state.userInfo
          this.score = this.info.score
         this.$emit('userInfoScuuess','fail')   //告诉父组件更新成功，是否需要重新获取数据。
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.fldv {
  width: 210px;
  height: 60px;
  line-height: 60px;
  font-size: 14px;
}
</style>
